<template>
  <AppLayout>

    <section class="product product-section" v-if="product">
      <div class="container">

        <ProductAlert :showDismissibleAlert="showDismissibleAlert" :product="product" />

        <div class="row pb-md-20">
          <div class="col-12 col-md-5">

            <ProductGallery v-if="product.media_gallery.length > 0" @changeIsInWishList="UpdateIsInWishList"
              :images="product.media_gallery" :isInWishList="$store.getters['user/isProductInWishlist'](product.sku)" />

            <ProductGallery v-if="product.media_gallery.length == 0" @changeIsInWishList="UpdateIsInWishList"
              :images="[product.image]" :isInWishList="$store.getters['user/isProductInWishlist'](product.sku)" />

          </div>

          <div class="col-12 col-md-7 ">

            <div class="product-details">
              <clientOnly>
                <h1 class="product--name d-block"> {{ product.name }} </h1>
                <span class="number"> <strong> Produktnummer : </strong> {{ product.sku }} </span>
                <span class="inhalt"> <strong>Inhalt :</strong> {{ product.inhalt }}</span>
                <ProductParameters v-if="product.__typename != 'BundleProduct'" :isInWishList="false" />
              </clientOnly>
            </div>

            <BundelTips class="d-none d-md-block" />

          </div>
        </div>

        <DescriptionTab />

        <div v-if="related.length > 0" class="product-gallery-block">
          <h2>{{ $t("up_sell_products") }}</h2>
          <MoreProductsCarousel :products="related" />
        </div>
        <div v-if="productForm != null">
          <dynamic-forms :product_sku="product.sku" type="product" />
        </div>

      </div>
    </section>

    <section v-else>
      <div class="container">
        <h4 class="no-product">{{ $t("no_product_found") }}</h4>
      </div>
    </section>

  </AppLayout>
</template>

<script>
import config from "@config";
import { mapGetters } from "vuex";
import ClientOnly from "vue-client-only";
import { isServer } from "@storefront/core/helpers";
import { isMobile, isTablet } from "mobile-device-detect";
import BundelTips from "@/base/core/components/BundelTips";
import DynamicForms from "@/base/core/components/DynamicForms";
import ProductAlert from "@/base/core//components/ProductAlert";
import AppLayout from "@/esf_kerkrade_vitanatura/core/components/AppLayout";
import MoreProductsCarousel from "@/base/core//components/MoreProductsCarousel";
import DescriptionTab from "@/esf_kerkrade_vitanatura/core/components/DescriptionTab";
import ProductParameters from "@/esf_kerkrade_vitanatura/core/components/ProductParameters";
import ProductGallery from "@/esf_kerkrade_vitanatura/core/components/product/ProductGallery";

export default {
  name: "ProductPage",
  components: {
    AppLayout,
    ProductGallery,
    ProductParameters,
    DescriptionTab,
    BundelTips,
    MoreProductsCarousel,
    ProductAlert,
    DynamicForms,
    ClientOnly,
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      related: "product/getRelated",
      productConfig: "product/getCurrentConfig",
    }),
    showDismissibleAlert() {
      return false;
    },
    productForm() {
      return this.$store.getters["forms/getProductForm"];
    },
    mobileCheck() {
      if (isMobile || isTablet) {
        return true;
      }
      return false;
    },
    metaInformation() {
      return {
        title: this.product ? this.product.meta_title ? this.product.meta_title : this.product.name || "" : "",
        keywords: this.product && this.product.meta_keyword ? this.product.meta_keyword || "" : "",
        description: this.product ? this.product.meta_description ? this.product.meta_description || "" : this.product.short_description || "" ? this.product.short_description.html || "" : "" : "",
        image: this.product ? this.product.image.large : "",
        price: this.product ? this.product.price_range.minimum_price.final_price.value || "" : "",
        priceCurrency: "EUR",
        pageTitle: this.product ? this.product.name || "" : "",
      };
    },
    isInStock() {
      return this.product.stock_status == "IN_STOCK" ? true : false;
    },
    finalPrice() {
      if (this.product.__typename == "ConfigurableProduct") {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          let found = null;
          if (this.productConfig && this.productConfig.variants) {
            found = this.productConfig.variants.find(v => {
              return v.product.sku === this.$store.getters["product/getCurrentChildSku"];
            });
          }
          if (found != null) {
            return found.product.price_range.minimum_price.final_price.value.toFixed(2);
          }
        }
        if (this.product.price_range.minimum_price.final_price.value != this.product.price_range.maximum_price.final_price.value) {
          return (
            this.product.price_range.minimum_price.final_price.value.toFixed(2) + " - " + this.product.price_range.aximum_price.final_price.value.toFixed(2).replace(".", ",")
          );
        }
        return this.product.price_range.minimum_price.final_price.value.toFixed(2);
      }
      if (this.product.price_range.minimum_price.final_price.value != this.product.price_range.maximum_price.final_price.value) {
        return (
          this.product.price_range.minimum_price.final_price.value.toFixed(2).replace(".", ",") + " - " + this.product.price_range.maximum_price.final_price.value
            .toFixed(2)
            .replace(".", ",")
        );
      }
      return this.product.price_range.minimum_price.final_price.value.toFixed(2);
    },
    regularPrice() {
      if (this.product.__typename == "ConfigurableProduct") {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.price_range.minimum_price.regular_price.value.toFixed(
              2
            );
          }
        }
        if (this.product.price_range.minimum_price.regular_price.value != this.product.price_range.maximum_price.regular_price.value) {
          return (
            this.product.price_range.minimum_price.regular_price.value.toFixed(
              2
            ) +
            " - " +
            this.product.price_range.maximum_price.regular_price.value
              .toFixed(2)
              .replace(".", ",")
          );
        }
        return this.product.price_range.minimum_price.regular_price.value.toFixed(
          2
        );
      }
      if (this.product.price_range.minimum_price.regular_price.value != this.product.price_range.maximum_price.regular_price.value) {
        return (
          this.product.price_range.minimum_price.regular_price.value
            .toFixed(2)
            .replace(".", ",") +
          " - " +
          this.product.price_range.maximum_price.regular_price.value
            .toFixed(2)
            .replace(".", ",")
        );
      }
      return this.product.price_range.minimum_price.regular_price.value.toFixed(
        2
      );
    },
  },
  watch: {
    product() {
      if (!isServer) {
        if (this.$gtm.enabled()) {
          window.dataLayer.push({
            event: "eec.detail",
            ecommerce: {
              detail: {
                currency: "EUR",
                value: this.finalPrice,
                products: [
                  {
                    name: this.product.name,
                    id: this.product.sku,
                    price: this.regularPrice,
                    discount: (this.regularPrice - this.finalPrice).toFixed(2),
                  },
                ],
              },
            },
          });
        }
      }
    },
  },
  methods: {
    // formateofGramm(data) {
    //   const final_calcultion = parseFloat(data.price_range.minimum_price.final_price.value) / parseInt(data.verkaufseinheit) * parseInt(data.grundeinheit);
    //   const text = `${data.verkaufseinheit} Gramm (${(final_calcultion).toFixed(2)} €* / ${data.grundeinheit} Gramm)`
    //   return text
    // },
    UpdateIsInWishList() {
      if (this.$store.getters["user/getIsLoggedIn"] == true) {
        if (
          this.$store.getters["user/isProductInWishlist"](this.product.sku) ==
          true
        ) {
          this.$store.dispatch("user/setProductWishlistStatus", {
            sku: this.product.sku,
            parentSku: null,
          });
        } else {
          if (this.product.__typename == "SimpleProduct") {
            this.$store.dispatch("user/setProductWishlistStatus", {
              sku: this.product.sku,
              parentSku: null,
            });
          } else {
            const retval = this.$store.getters["product/getCurrentChildSku"];
            if (retval == null) {
              const msg = {
                type: "danger",
                title: this.$t("wishlist error"),
                text: this.$t("wishlist_select_options"),
              };
              this.$store.dispatch("messages/sendMessage", { message: msg });
              //this.$router.push("/" + this.product.url_key);
            } else {
              this.$store.dispatch("user/setProductWishlistStatus", {
                sku: this.$store.getters["product/getCurrentChildSku"],
                parentSku: this.product.sku,
              });
            }
          }
        }
      } else {
        const msg = {
          type: "danger",
          title: this.$t("wishlist error"),
          text: this.$t("wishlist_logged_in"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
    updateBreadcrumbs() {
      if (this.product) {
        const current = this.product.name;
        let breadcrumb = { current, routes: [] };
        if (this.$store.state.route.from.path != "/") {
          if (this.category) {
            let retval = this.product.categories.find(
              (i) => i.id === this.category.id
            );
            if (retval !== false) {
              const { breadcrumbs } = this.category;
              if (breadcrumbs != null) {
                breadcrumbs.sort((a, b) => {
                  if (a.category_level < b.category_level) return -1;
                  if (a.category_level > b.category_level) return 1;
                  return 0;
                });

                let path = "";
                breadcrumbs.forEach((element) => {
                  if (path.length > 0) {
                    path = path + "/";
                  }
                  path = path + element.category_url_key;
                  breadcrumb.routes.push({
                    name: element.category_name,
                    route_link: "/" + path,
                  });
                });
              }

              breadcrumb.routes.push({
                name: this.category.name,
                route_link: this.category.url_path,
              });
            }
          }
        }
        this.$store.commit("breadcrumbs/set", breadcrumb);
      }
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "title",
          content: this.metaInformation["title"],
        },
        {
          name: "keywords",
          content: this.metaInformation["keywords"],
        },
        {
          name: "description",
          content: this.metaInformation["description"],
        },
        { property: "og:type", content: "product" },
        {
          property: "og:title",
          content: this.metaInformation["title"],
        },
        {
          property: "og:image",
          content: this.metaInformation["image"],
        },
        {
          property: "og:description",
          content: this.metaInformation["description"],
        },
        {
          property: "product:price:amount",
          content: this.metaInformation["price"],
        },
        {
          property: "product:price:currency",
          content: this.metaInformation["priceCurrency"],
        },
      ],
      title: this.metaInformation["pageTitle"],
    };
  },
  async mounted() {
    this.updateBreadcrumbs();
    if (config.helloRetail && config.helloRetail.enabled) {
      if (!isServer) {
        var hello_retail_id = this.$cookies.get("hello_retail_id");
        await this.$store.dispatch("product/loadCrossSellProducts", {
          sku: this.product.sku,
          hello_retail_id,
        });
        await this.$store.dispatch("product/loadUpSellProducts", {
          sku: this.product.sku,
          hello_retail_id,
        });
      }
    } else {
      await this.$store.dispatch("product/loadCrossSell", {
        sku: this.product.sku,
      });
      await this.$store.dispatch("product/loadUpSell", {
        sku: this.product.sku,
      });
    }
    if (!isServer) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({
          event: "eec.detail",
          ecommerce: {
            detail: {
              currency: "EUR",
              value: this.finalPrice,
              products: [
                {
                  name: this.product.name,
                  id: this.product.sku,
                  price: this.regularPrice,
                  discount: (this.regularPrice - this.finalPrice).toFixed(2),
                },
              ],
            },
          },
        });
      }
    }
  },
};
</script>